@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/GilroyLight/font.woff2) format(woff2),
    url(./assets/fonts/GilroyLight/font.woff) format(woff);
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/GilroyLightItalic/font.woff2) format(woff2),
    url(./assets/fonts/GilroyLightItalic/font.woff) format(woff);
  font-weight: 300;
  font-style: italic, oblique;
}
@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/GilroyExtraBold/font.woff2) format(woff2),
    url(./assets/fonts/GilroyExtraBold/font.woff) format(woff);
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/GilroyExtraBoldItalic/font.woff2) format(woff2),
    url(./assets/fonts/GilroyExtraBoldItalic/font.woff) format(woff);
  font-weight: 700;
  font-style: italic, oblique;
}

body {
  margin: 0;
  font-family: "Gilroy", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swagger-ui {
  font-family: inherit !important;
}

.amplify-button--primary:hover {
	--amplify-internal-button-background-color: #947BF3 !important;
}

.amplify-button--primary {
	--amplify-internal-button-background-color: #22223A !important;
}
